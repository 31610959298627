    .sd-accordion li {
        display: block !important;
    }

    .thumb,
    .thumb::-webkit-slider-thumb {
        -webkit-appearance: none;
        -webkit-tap-highlight-color: transparent;
    }

    .thumb {
        pointer-events: none;
        position: absolute;
        height: 0;
        width: 260px !important;
        outline: none;
        z-index: 3;
    }

    /* .thumb--zindex-3 {
        z-index: 3;
    }

    .thumb--zindex-4 {
        z-index: 4;
    }

    .thumb--zindex-5 {
        z-index: 5;
    } */

    .slider {
        position: relative;
        width: 260px !important;
    }

    .side-bar-listing .color-button {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 20px;
    }

    .side-bar-listing .color-button button {
        border: none;
        padding: 0px;
        font-size: 19px;
        height: 30px;
        width: 30px;
        -webkit-box-shadow: 0 2px 5px #b7b7b7;
        box-shadow: 0 2px 5px #b7b7b7;
        cursor: pointer;
        margin-bottom: 5px;
        margin-right: 8px;
        -webkit-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }

    /* .side-bar-listing .color-button button.active {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23fff%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E");
        background-color: #722257;
        box-shadow: 0 0 2px darkslategray;
        background-repeat: no-repeat;
        border: 2px solid transparent;
    } */

    .side-bar-listing .color-button button.active {
        box-shadow: 0 0 2px darkslategray;
        background-repeat: no-repeat;
        border: 2px solid #722257;
    }

    .side-bar-listing .color-button button.active:after {
        top: 10px;
        right: 4px;
        height: 15px;
        width: 15px;
        background-size: 70%;
        background-repeat: no-repeat;
        position: absolute;
    }

    .side-bar-listing .color-button .round-1 {
        -webkit-border-radius: 50%;
        border-radius: 50%;
        background-color: #FFD700;
        border: 2px solid #FFD700;
    }

    .side-bar-listing .color-button .round-2 {
        -webkit-border-radius: 50%;
        border-radius: 50%;
        background-color: #F6CFCA;
        border: 2px solid #F6CFCA;
    }

    .side-bar-listing .color-button .round-3 {
        -webkit-border-radius: 50%;
        border-radius: 50%;
        background-color: #f2f2f2;
        border: 2px solid #f2f2f2;
    }

    .over_height_scroller {
        max-height: 7.5rem;
        overflow-y: scroll;
    }



    #over_height_scroller_style::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
    }

    #over_height_scroller_style::-webkit-scrollbar {
        width: 0.3rem;
        background-color: #F5F5F5;
    }

    #over_height_scroller_style::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: #722257;
    }