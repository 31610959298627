.form-group .uk-checkbox {
    display: inline-block;
    height: 15px;
    width: 15px;
    overflow: hidden;
    margin-top: 4px;
    margin-right: 6px;
    vertical-align: middle;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border: 1px solid #cccccc;
    transition: 0.2s ease-in-out;
    transition-property: background-color, border;
}

.form-group .uk-radio {
    display: inline-block;
    height: 15px;
    width: 15px;
    overflow: hidden;
    margin-top: 4px;
    margin-right: 6px;
    vertical-align: middle;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border: 1px solid #cccccc;
    transition: 0.2s ease-in-out;
    transition-property: background-color, border;
}

.light-layout {
    background-color: #efe6db;
}

.uk-button-secondary.add-cart-button-hide {
    border: 1px solid #72225775;
    background: #fff;
    color: #72225775;
    letter-spacing: 3px;
    font-weight: 700;
    font-size: 12px;
    padding: 0 5px;
}

.uk-button-secondary.add-cart-button-hide:hover {
    border: 1px solid #72225775;
    background: #fff;
    color: #72225775;
    letter-spacing: 3px;
    font-weight: 700;
    font-size: 12px;
    padding: 0 5px;
}

.uk-button-primary.buy-now-button-hide {
    letter-spacing: 3px;
    font-weight: 700;
    font-size: 12px;
    padding: 0 5px;
    background-color: #72225775;
    color: #fff !important;
    border: 1px solid transparent;
    opacity: 0.5;
}

.uk-button-primary.buy-now-button-hide:hover {
    letter-spacing: 3px;
    font-weight: 700;
    font-size: 12px;
    padding: 0 5px;
    background-color: #72225775;
    color: #fff !important;
    border: 1px solid transparent;
}

h6.failed{
    opacity: 1;
    margin: 5px 0 0 0;
}

h6.passed{
    opacity: 0;
    margin: 5px 0 0 0;
}

.otp_popup{
    height: fit-content;
}