.image-gallery .heading-section {
    max-width: fit-content;
    width: 100%;
    margin-bottom: 44px;
}

@media screen and (max-width: 767px) {
    .image-gallery {
        margin-bottom: 20px;
    }

    .image-gallery .heading-section {
        margin-bottom: 20px;
    }
}

.instagram1 {
    width: 100%;
    height: 376px;
    overflow: hidden;
    line-height: initial;
}

.instagram2 {
    height: 0px;
}

.instagram3 {
    visibility: visible;
    position: static;
    margin: 0px;
    left: -999em;
    display: inline-block;
    border: none;
    width: 100%;
    height: 376px;
    min-height: 376px;
}