/*! CSS Used from: Embedded */


.product_details_1241{
  color: #000000;
  font-weight: lighter;
  margin-left: 1rem;
}
a {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  color: #722257;
  text-decoration: none !important;
}

small {
  font-size: 80%;
}

svg {
  vertical-align: middle;
}

svg {
  max-width: 100%;
  height: auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

p,
ul,
fieldset {
  margin: 0 0 20px 0;
}

*+p {
  margin-top: 20px;
}

h2,
h3 {
  margin: 0 0 20px 0;
  font-family: gillSans;
  font-weight: 500;
  color: #fff;
  text-transform: none;
}

h2 {
  font-size: 27.2px;
  font-size: 1.7rem;
  line-height: 1.3;
}

h3 {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.4;
}

@media (min-width: 960px) {
  h2 {
    font-size: 32px;
    font-size: 2rem;
  }
}

ul {
  padding-left: 30px;
}

hr {
  overflow: visible;
  text-align: inherit;
  margin: 0 0 20px 0;
  border: 0;
  border-top: 1px solid #e5e5e5;
}

*+hr {
  margin-top: 20px;
}

:focus {
  outline: none;
}

::-moz-selection {
  background: #39f;
  color: #fbf8f5;
  text-shadow: none;
}

::selection {
  background: #39f;
  color: #fbf8f5;
  text-shadow: none;
}

::-moz-selection {
  background: #722257 !important;
  text-shadow: none;
}

::selection {
  background: #722257 !important;
  text-shadow: none;
}

.uk-list {
  padding: 0;
  list-style: none;
}

.uk-list>*> :last-child {
  margin-bottom: 0;
}

.uk-list> :nth-child(n+2) {
  margin-top: 10px;
}

.uk-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin-bottom: 20px;
}

.uk-table th {
  padding: 16px 12px;
  text-align: left;
  vertical-align: bottom;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: normal;
  color: #999;
  text-transform: uppercase;
}

.uk-table td {
  padding: 16px 12px;
  vertical-align: top;
}

.uk-table td> :last-child {
  margin-bottom: 0;
}

.uk-table-divider> :not(:first-child)>tr {
  border-top: 1px solid #e5e5e5;
}

.uk-table-justify th:first-child,
.uk-table-justify td:first-child {
  padding-left: 0;
}

.uk-table-justify th:last-child,
.uk-table-justify td:last-child {
  padding-right: 0;
}

.uk-table tbody tr {
  transition: background-color 0.1s linear;
}

.uk-icon {
  margin: 0;
  border: none;
  border-radius: 0;
  overflow: visible;
  font: inherit;
  color: inherit;
  text-transform: none;
  padding: 0;
  background-color: transparent;
  display: inline-block;
  fill: currentcolor;
  line-height: 0;
}

.product-detail-main .engrave-offcanvas .uk-icon {
  color: #000000;
}

.uk-icon::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.uk-icon:not(.uk-preserve) [stroke*='#']:not(.uk-preserve) {
  stroke: currentcolor;
}

.uk-icon>* {
  transform: translate(0, 0);
}

.uk-input,
.uk-select {
  box-sizing: border-box;
  margin: 0;
  border-radius: 0;
  font: inherit;
}

.uk-input {
  overflow: visible;
}

.uk-select {
  text-transform: none;
}

.uk-input::-moz-placeholder {
  opacity: 1;
}

.uk-fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

.uk-input {
  -webkit-appearance: none;
}

.uk-input,
.uk-select {
  max-width: 100%;
  width: 100%;
  border: 0 none;
  padding: 0 10px;
  background: #fff;
  color: #000000;
  border: 1px solid #e5e5e5;
  transition: 0.2s ease-in-out;
  transition-property: color, background-color, border;
}

.uk-input,
.uk-select:not([multiple]):not([size]) {
  height: 40px;
  vertical-align: middle;
  display: inline-block;
}

.uk-input:focus,
.uk-select:focus {
  outline: none;
  background-color: #fff;
  color: #000000;
  border-color: #1e87f0;
}

.uk-input:disabled,
.uk-select:disabled {
  background-color: #f8f8f8;
  color: #999;
  border-color: #e5e5e5;
}

.uk-input::placeholder {
  color: #999;
}

.uk-select:not([multiple]):not([size]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 20px;
  background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23722257%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23722257%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A);
  background-repeat: no-repeat;
  background-position: 100% 50%;
}

.uk-select:not([multiple]):not([size]) option {
  color: #722257;
}

.uk-select:not([multiple]):not([size]):disabled {
  background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23999%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23999%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A);
}

.uk-form-label {
  color: #333;
  font-size: 14px;
  font-size: 0.875rem;
}

.uk-form-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #999;
}

.uk-form-icon:hover {
  color: #722257;
}

.uk-form-icon:not(a):not(button):not(input) {
  pointer-events: none;
}

.uk-form-icon:not(.uk-form-icon-flip)~.uk-input {
  padding-left: 40px !important;
}

.uk-button {
  margin: 0;
  border: none;
  overflow: visible;
  font: inherit;
  color: inherit;
  text-transform: none;
  -webkit-appearance: none;
  border-radius: 0;
  display: inline-block;
  box-sizing: border-box;
  padding: 0 30px;
  vertical-align: middle;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 38px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.1s ease-in-out;
  transition-property: color, background-color, border-color;
}

.uk-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.uk-button:hover {
  text-decoration: none;
}

.uk-button-default {
  background-color: transparent;
  color: #333;
  border: 1px solid #e5e5e5;
}

.uk-button-default:hover {
  background-color: transparent;
  color: #333;
  border-color: #b2b2b2;
}

.uk-button-default:active {
  background-color: transparent;
  color: #333;
  border-color: #999999;
}

.uk-button-primary {
  background-color: #722257;
  color: #fff;
  border: 1px solid #fff;
}

.uk-button-primary:hover {
  background-color: #000000;
  color: #fff;
}

.uk-button-primary:active {
  background-color: #4b1639;
  color: #fbf8f5;
}

.uk-button-secondary {
  background-color: #222;
  color: #fff;
  border: 1px solid transparent;
}

.uk-button-secondary:hover {
  background-color: #000000;
  color: #fff;
}

.uk-button-secondary:active {
  background-color: #090909;
  color: #fbf8f5;
}

.uk-button-default:disabled,
.uk-button-primary:disabled,
.uk-button-secondary:disabled {
  background-color: transparent;
  color: #999;
  border-color: #e5e5e5;
}

.uk-card {
  position: relative;
  box-sizing: border-box;
  transition: box-shadow 0.1s ease-in-out;
}

.uk-card-body {
  display: flow-root;
  padding: 30px 30px;
}

@media (min-width: 1200px) {
  .uk-card-body {
    padding: 40px 40px;
  }
}

.uk-card-body> :last-child {
  margin-bottom: 0;
}

.uk-card-title {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.4;
}

.uk-card-default {
  background-color: #fff;
  color: #000000;
  /* box-shadow: 0 5px 15px rgba(0, 0, 0, .08); */
}

.uk-card-default .uk-card-title {
  color: #333;
}

.uk-accordion {
  padding: 0;
  list-style: none;
}

.uk-accordion-title {
  display: block;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.4;
  color: #333;
  overflow: hidden;
}

.uk-accordion-title:before {
  content: "";
  width: 1.4em;
  height: 1.4em;
  margin-left: 10px;
  float: right;
  background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23722257%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23722257%22%20width%3D%221%22%20height%3D%2213%22%20x%3D%226%22%20y%3D%220%22%20%2F%3E%0A%3C%2Fsvg%3E);
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.uk-accordion-title:hover {
  color: #722257;
  text-decoration: none;
}

.uk-accordion-content {
  display: flow-root;
  margin-top: 20px;
}

.uk-accordion-content> :last-child {
  margin-bottom: 0;
}

.uk-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}

.uk-grid>* {
  margin: 0;
}

.uk-grid>*> :last-child {
  margin-bottom: 0;
}

.uk-grid {
  margin-left: 0px;
}

.uk-grid>* {
  padding-left: 0px;
}

.uk-grid+.uk-grid,
.uk-grid>.uk-grid-margin,
*+.uk-grid-margin {
  margin-top: 0px;
}

@media (min-width: 1200px) {
  .uk-grid {
    margin-left: -40px;
  }

  .uk-grid>* {
    padding-left: 40px;
  }

  .uk-grid+.uk-grid,
  .uk-grid>.uk-grid-margin,
  *+.uk-grid-margin {
    margin-top: 40px;
  }
}

.uk-grid-collapse {
  margin-left: 0;
}

.uk-grid-collapse>* {
  padding-left: 0;
}

.uk-grid-collapse>.uk-grid-margin {
  margin-top: 0;
}

.uk-iconnav {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-left: -10px;
}

.uk-iconnav>* {
  padding-left: 10px;
}

[class*='uk-child-width']>* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}

.uk-child-width-1-2>* {
  width: 50%;
}

@media (min-width: 640px) {
  .uk-child-width-1-1\@s>* {
    width: 100%;
  }

  .uk-child-width-1-2\@s>* {
    width: 50%;
  }

  .uk-child-width-1-3\@s>* {
    width: 33.32223%;
  }
}

@media (min-width: 960px) {
  .uk-child-width-1-3\@m>* {
    width: 33.32223%;
  }
}

@media (min-width: 1200px) {
  .uk-child-width-1-2\@l>* {
    width: 50%;
  }
}

[class*='uk-width'] {
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
}

.uk-width-small {
  width: 150px;
}

@media (min-width: 640px) {
  .uk-width-1-1\@s {
    width: 100%;
  }
}

@media (min-width: 960px) {
  .uk-width-3-4\@m {
    width: 75%;
  }
}

@media (min-width: 1200px) {
  .uk-width-1-1\@l {
    width: 100%;
  }

  .uk-width-1-2\@l {
    width: 50%;
  }
}

.uk-text-left {
  text-align: left !important;
}

@media (min-width: 960px) {
  .uk-text-right\@m {
    text-align: right !important;
  }
}

@media (min-width: 1200px) {
  .uk-text-right\@l {
    text-align: right !important;
  }
}

.uk-text-middle {
  vertical-align: middle !important;
}

[class*='uk-inline'] {
  display: inline-block;
  position: relative;
  max-width: 100%;
  vertical-align: middle;
  -webkit-backface-visibility: hidden;
}

.uk-flex-left {
  justify-content: flex-start;
}

@media (min-width: 640px) {
  .uk-flex-center\@s {
    justify-content: center;
  }
}

@media (min-width: 960px) {
  .uk-flex-left\@m {
    justify-content: flex-start;
  }
}

.uk-margin {
  margin-bottom: 20px;
}

.uk-padding-small {
  padding: 15px;
}

.uk-padding-remove {
  padding: 0 !important;
}

[hidden] {
  display: none !important;
}

@media (max-width: 959px) {
  .uk-visible\@m {
    display: none !important;
  }
}

@media print {

  *,
  *:before,
  *:after {
    background: transparent !important;
    color: black !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  thead {
    display: table-header-group;
  }

  tr {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}

::selection {
  background: #722257 !important;
  text-shadow: none;
}

.uk-grid {
  margin-left: 0;
}

h2 {
  color: #505050 !important;
  padding: 20px 0;
  margin: 0 !important;
  font-family: bigCalsonMedium !important;
}

p {
  color: #505050;
  font-size: 16px;
  font-family: gillSans;
}

.uk-input:focus,
.uk-select:focus {
  outline: none;
  background-color: #fff;
  color: #000000;
  border-color: #722257;
}

.uk-button-primary.buy-now {
  letter-spacing: 3px;
  font-weight: 700;
  font-size: 12px;
  padding: 0 5px;
}

.uk-button-secondary.add-cart {
  border: 1px solid #722257;
  background: #fff;
  color: #722257;
  letter-spacing: 3px;
  font-weight: 700;
  font-size: 12px;
  padding: 0 5px;
}

.uk-button-secondary.add-cart:hover {
  border: 1px solid #000000;
  background: #000000;
  color: #fff;
}

.side-bar-listing .small-review a {
  font-size: 12px;
  letter-spacing: 2px;
  color: #707070;
}

.side-bar-listing article .big-price {
  font-size: 20px;
  margin: 0 20px;
  font-weight: bold;
  color: #505050;
}

.side-bar-listing article small {
  font-size: 14px;
  color: #505050;
}

.side-bar-listing article del {
  font-size: 14px;
  color: #a7a7a7;
}

.side-bar-listing .uk-accordion-title {
  font-size: 16px;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(204, 204, 204, 0.5098);
}

.side-bar-listing .uk-fieldset label {
  color: #000000;
  margin: 4px 0;
}

.product-detail-main .need-help .uk-card-body {
  padding: 30px 40px;
}

.product-detail-main .need-help .need-help-ico span {
  padding: 0 35px;
}

.product-detail-main .caps-text {
  text-transform: uppercase;
}

.product-detail-main .pricebreakup {
  padding: 10px 0;
}

.product-detail-main .pricebreakup a {
  color: #722257;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 12px;
}

.product-detail-main .red-discount {
  background-color: #A02816;
  padding: 6px 15px;
}

.product-detail-main .red-discount span {
  font-size: 11px;
  color: #fff;
  opacity: 0.7;
}

.product-detail-main h3.uk-card-title {
  font-size: 14px !important;
  text-transform: uppercase;
  font-family: gillSans !important;
}

.product-detail-main .para-one {
  margin: 20px;
}

.product-detail-main .estimated-box {
  background: #F5F5F5;
}

.product-detail-main .estimated-box a.learn-more {
  color: #722257;
}

.product-detail-main .estimated-box .uk-card-body {
  padding: 30px 30px !important;
}

.product-detail-main .engrave-accordion .uk-accordion-title {
  font-size: 14px;
  text-transform: capitalize;
  border-bottom: none;
}

/* .product-detail-main .engrave-offcanvas .uk-offcanvas-bar {
background-color: #fff;
} */

.product-detail-main .checked {
  color: orange;
}

.product-detail-main .quantity {
  display: inline-block;
}

.product-detail-main .quantity .input-text.qty {
  width: 35px;
  height: 39px;
  padding: 0 5px;
  font-size: 19px;
  text-align: center;
  background-color: transparent;
  border: 1px solid #efefef;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type=number] {
  -moz-appearance: textfield;
}

.product-detail-main .quantity.buttons_added {
  text-align: left;
  position: relative;
  white-space: nowrap;
  vertical-align: top;
}

.product-detail-main .quantity.buttons_added input {
  display: inline-block;
  margin: 0;
  vertical-align: top;
  box-shadow: none;
}

.product-detail-main .quantity.buttons_added .minus,
.product-detail-main .quantity.buttons_added .plus {
  padding: 7px 10px 8px;
  height: 41px;
  background-color: #ffffff;
  border: 1px solid #efefef;
  cursor: pointer;
}

.product-detail-main .quantity.buttons_added .minus {
  border-right: 0;
}

.product-detail-main .quantity.buttons_added .plus {
  border-left: 0;
}

.product-detail-main .quantity.buttons_added .minus:hover,
.product-detail-main .quantity.buttons_added .plus:hover {
  background: #eeeeee;
}

.product-detail-main .quantity.buttons_added .minus:focus,
.product-detail-main .quantity.buttons_added .plus:focus {
  outline: none;
}

.engraveproductwith .uk-iconnav {
  padding: 8px;
}

.engraveproductwith span {
  color: #722257;
  margin-right: 10px;
}



@media (max-width: 1200px) {
  .uk-grid>* {
    padding-left: 5px;
  }

  .form-select-size .uk-form-box {
    padding: 20px 0;
  }
}

@media (max-width: 768px) {
  .help-need p {
    text-align: center;
  }

  .uk-card-body.help-need {
    text-align: center;
  }

  .pro-dt-mob {
    margin-top: 16px;
    display: block;
  }

  .engraveproductwith .uk-iconnav {
    padding: 8px;
  }

  h2 {
    font-size: 20px;
    padding: 10px 0;
  }

  .need-help .uk-card-body {
    padding: 6px 33px !important;
  }

  .need-help p {
    margin-top: 20px;
  }

  .need-help span {
    padding: 0 15px !important;
    margin-bottom: 20px;
  }
}

/*Share link css*/

.popup :is(.uk-modal-header, .icons, .field) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup .uk-modal-header {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebedf9;
}

.uk-modal-header h1 {
  font-size: 21px;
  font-weight: 600;
}

.popup .content {
  margin: 20px 0;
}

.popup .icons {
  margin: 15px 0 20px 0;
}

.content p {
  font-size: 16px;
}

.content .icons div {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  font-size: 28px;
  text-align: center;
  text-decoration: none;
  border: 1px solid transparent;
}

.icons div span {
  transition: transform 0.3s ease-in-out;
}

.icons div:nth-child(1) {
  color: #1877F2 !important;
  border-color: #b7d4fb !important;

  /* color: #722257 !important;
  border-color: #897181 !important; */
}


.icons div:nth-child(1):hover {
  background: #1877F2 !important;
  /* background: #722257 !important; */
}

.icons div:nth-child(2) {
  color: #46C1F6 !important;
  border-color: #b6e7fc !important;
  /* color: #722257 !important;
  border-color: #897181 !important; */
}

.icons div:nth-child(2):hover {
  background: #46C1F6 !important;
  /* background: #722257 !important; */
}

.icons div:nth-child(3) {
  color: #25D366 !important;
  border-color: #bef4d2 !important;
  /* color: #722257 !important;
  border-color: #897181 !important; */

}

.icons div:nth-child(3):hover {
  background: #25D366 !important;
  /* background: #722257 !important; */
}

.icons div:nth-child(4) {
  color: #4c4c4c !important;
  border-color: #979797 !important;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.icons div:nth-child(4):hover {
  background: #4c4c4c !important;
}

.icons div:nth-child(4) h6 {
  color: #4c4c4c !important;
}

.icons div:nth-child(4):hover h6 {
  color: #ffff !important;
}

.icons div:nth-child(5) {
  color: #e1306c !important;
  border-color: #f5bccf !important;
}

.icons div:nth-child(5):hover {
  background: #e1306c !important;
}

.icons div:nth-child(6) {
  color: #ff6961 !important;
  border-color: #ff6961 !important;
}

.icons div:nth-child(6):hover {
  background: #ff6961 !important;
}

.icons div:hover {
  color: #fff !important;
  border-color: transparent;
}

.icons div:hover i {
  transform: scale(1.2);
}

.content .field {
  margin: 12px 0 -5px 0;
  height: 45px;
  border-radius: 4px;
  padding: 0 5px;
  border: 1px solid #e1e1e1;
}

.field.active {
  border-color: #7d2ae8;
}

.field i {
  width: 50px;
  font-size: 18px;
  text-align: center;
}

.field.active i {
  color: #7d2ae8;
}

.field input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 15px;
}

.field button {
  color: #fff;
  padding: 5px 18px;
  background: #7d2ae8;
}

.field button:hover {
  background: #8d39fa;
}

/*Css for pincode verify if prdt above 49K*/
.text-info-color {
  color: #722257 !important;
}

.share-facebook {
  color: #1877F2 !important;
  border-color: #b7d4fb !important;
  width: 40px;
  height: 40px;
}

.share-facebook:hover {
  background: #1877F2 !important;
  color: #fff !important;
}
.share-twitter {
  color: #46C1F6 !important;
  border-color: #b6e7fc !important;
  width: 40px;
  height: 40px;
}
.share-twitter:hover {
  background: #46C1F6 !important;
  color: #fff !important;
}
.share-whatsapp {
  color: #25D366 !important;
  border-color: #bef4d2 !important;
  width: 40px;
  height: 40px;
}
.share-whatsapp:hover {
  background: #25D366 !important;
  color: #fff !important;
}
.share-copy {
  color: #4c4c4c !important;
  border-color: #979797 !important;
  width: 40px;
  height: 40px;
}
.share-copy:hover {
  background: #4c4c4c !important;
  color: #fff !important;
}