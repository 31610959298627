@font-face {
    font-family: big-caslon;
    src: url(/assets/fonts/new_template/fonts/big-caslon.woff2);
    font-display: swap;
}

@font-face {
    font-family: gillSans;
    src: url("/assets/fonts/new_template/fonts/Gill-Sans.woff");
    font-display: swap;
}

@font-face {
    font-family: gillSansMedium;
    src: url("/assets/fonts/new_template/fonts/Gill-Sans-Medium.woff");
    font-display: swap;
}

.footer-element {
    display: flex;
    justify-content: flex-end;
    background-color: #fbf8f5;
}

@media screen and (min-width: 768px) {
    .left-column {
        padding: 15px;
    }
}

@media screen and (max-width: 1300px) {
    .left-column {
        width: 100%;
        max-width: 90%;
        margin: 0 auto;
    }

    .right-column {
        order: 0;
        justify-content: unset;
        max-width: 100%;
    }
}

.left-column {
    display: flex;
    align-content: center;
    /* min-height: 540px; */
    /* max-width: 850px; */
    flex-wrap: wrap;
}

.column {
    width: 33.33%;
    align-self: flex-start;
}

h3.footer-toggle {
    margin: auto;
    font-family: gillSansMedium;
    font-weight: 500;
    font-size: 16.38px;
    color: #000000;
}

/* @media screen and (min-width: 1024px) {
    ul.inner {
        display: block !important;
    }
} */

@media screen and (max-width: 1024px) {
    .column {
        width: 100%;
        align-self: flex-end;
    }

    ul.inner {
        display: block;
    }
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.inner li {
    margin-top: 13.5px;
    display: block;
    font-family: gillSans;
}

.right-column {
    background: #722257;
    color: #fff;
    /* max-width: 800px; */
    /* min-width: 42%; */
    text-align: center;
    align-items: center;
    justify-content: center;
    min-height: 540px;
    display: flex;
}

.right-inner {
    max-width: 380px;
    margin: 0 auto;
    text-align: left;
}

.right-inner h2 {
    font-size: 38px !important;
    color: #fff !important;
    font-family: Big Caslon, Medium;
    font-weight: 400;
    text-transform: capitalize;
}

.newsletter-outer {
    margin-top: 30px;
}

.right-inner span {
    font-size: 18px;
    font-family: Big Caslon, Medium;
}

.newsletter {
    margin: 35px 0 65px;
    position: relative;
    max-width: 380px;
}

input#newsletter {
    border: none;
    background: 0 0;
    color: #fff;
    border-bottom: 1px solid #fff;
    padding-bottom: 10px;
    width: 100%;
    outline: 0;
}

button.action.subscribe.primary {
    position: absolute;
    border: none;
    right: 0;
    top: 5px;
    background: url(/assets/images/new_template/images/group-198.svg) no-repeat;
    width: 19px;
    height: 12px;
    cursor: pointer;
}

button.action.subscribe.primary span {
    font-size: 0;
}

.social-links {
    max-width: 265px;
    display: flex;
    justify-content: space-between;
}

.social-links a {
    width: 20px;
    background: url(/assets/images/new_template/images/social-icon.svg) no-repeat;
    height: 20px;
    display: inline-block;
}

.social-links a.social-2 {
    background-position: -39px 0;
}

.social-links a.social-3 {
    background-position: -80px 0;
}

.social-links a.social-4 {
    background-position: -121px 0;
    width: 22px;
}

.social-links a.social-5 {
    background-position: -165px 0;
}

.social-links a.social-6 {
    background-position: -207px 0;
}

.social-links a.social-7 {
    background-position: -247px 0;
}

.payment-links {
    margin-top: 35px;
}

.inner a {
    margin-top: 13.5px;
    display: block;
    font-family: gillSans !important;
    color: #000 !important;
}

