a {
    color: #000000;
    text-decoration: none;
    cursor: pointer;
    font-family: gillSans;
}

a:hover {
    color: #722257;
    text-decoration: none !important;
}

b {
    font-weight: bolder;
}

img,
svg {
    vertical-align: middle;
}

img,
svg {
    max-width: 100%;
    height: auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

p,
ul,
fieldset {
    margin: 0 0 20px 0;
}

*+p {
    margin-top: 20px;
}

h2,
.uk-h2,
h3 {
    margin: 0 0 20px 0;
    font-family: gillSans;
    font-weight: 500;
    color: #fff;
    text-transform: none;
}

*+h3 {
    margin-top: 40px;
}

h2,
.uk-h2 {
    font-size: 27.2px;
    font-size: 1.7rem;
    line-height: 1.3;
}

h3 {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.4;
}

@media (min-width: 960px) {

    h2,
    .uk-h2 {
        font-size: 32px !important;
        font-size: 2rem;
    }
}

ul {
    padding-left: 30px;
}

hr {
    overflow: visible;
    text-align: inherit;
    margin: 0 0 20px 0;
    border: 0;
    border-top: 1px solid #e5e5e5;
}

*+hr {
    margin-top: 20px;
}

:focus {
    outline: none;
}

::-moz-selection {
    background: #39f;
    color: #fbf8f5;
    text-shadow: none;
}

::selection {
    background: #39f;
    color: #fbf8f5;
    text-shadow: none;
}

main {
    display: block;
}

[class*='uk-divider'] {
    border: none;
    margin-bottom: 20px;
}

*+[class*='uk-divider'] {
    margin-top: 20px;
}

.uk-divider-icon {
    position: relative;
    height: 20px;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22none%22%20stroke%3D%22%23e5e5e5%22%20stroke-width%3D%222%22%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%227%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.uk-divider-icon:before,
.uk-divider-icon:after {
    content: "";
    position: absolute;
    top: 50%;
    max-width: calc(50% - 25px);
    border-bottom: 1px solid #e5e5e5;
}

.uk-divider-icon:before {
    right: calc(50% + 25px);
    width: 100%;
}

.uk-divider-icon:after {
    left: calc(50% + 25px);
    width: 100%;
}

.uk-icon {
    margin: 0;
    border: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    overflow: visible;
    font: inherit;
    color: inherit;
    text-transform: none;
    padding: 0;
    background-color: transparent;
    display: inline-block;
    fill: currentcolor;
    line-height: 0;
}

.uk-icon::-moz-focus-inner {
    border: 0;
    padding: 0;
}

.uk-icon:not(.uk-preserve) [stroke*='#']:not(.uk-preserve) {
    stroke: currentcolor;
}

.uk-icon>* {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
}

.uk-radio,
.uk-checkbox {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    font: inherit;
}

.uk-fieldset {
    border: none;
    margin: 0;
    padding: 0;
}

.uk-radio,
.uk-checkbox {
    display: inline-block;
    height: 16px;
    width: 16px;
    overflow: hidden;
    margin-top: -4px;
    vertical-align: middle;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border: 1px solid #cccccc;
    -webkit-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
    -webkit-transition-property: background-color, border;
    -o-transition-property: background-color, border;
    transition-property: background-color, border;
}

.uk-radio {
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

.uk-radio:focus,
.uk-checkbox:focus {
    background-color: #000000;
    background-color: rgba(0, 0, 0, 0);
    outline: none;
    border-color: #ccc;
}

.uk-radio:checked,
.uk-checkbox:checked {
    background-color: #722257;
    border-color: transparent;
}

.uk-radio:checked:focus,
.uk-checkbox:checked:focus {
    background-color: #722257;
}

.uk-radio:checked {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23fff%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

.uk-checkbox:checked {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23fff%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}

.uk-radio:disabled,
.uk-checkbox:disabled {
    background-color: #f8f8f8;
    border-color: #e5e5e5;
}

.uk-radio:disabled:checked {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23999%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

.uk-checkbox:disabled:checked {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23999%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}

.uk-button {
    margin: 0;
    border: none;
    overflow: visible;
    font: inherit;
    color: inherit;
    text-transform: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 15px !important;
    vertical-align: middle;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 38px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: 0.1s ease-in-out;
    -o-transition: 0.1s ease-in-out;
    transition: 0.1s ease-in-out;
    -webkit-transition-property: color, background-color, border-color;
    -o-transition-property: color, background-color, border-color;
    transition-property: color, background-color, border-color;
}

.uk-button::-moz-focus-inner {
    border: 0;
    padding: 0;
}

.uk-button:hover {
    text-decoration: none;
}

.uk-button-default {
    background-color: transparent;
    color: #333;
    border: 1px solid #e5e5e5;
}

.uk-button-default:hover {
    background-color: transparent;
    color: #333;
    border-color: #b2b2b2;
}

.uk-button-default:active {
    background-color: transparent;
    color: #333;
    border-color: #999999;
}

.uk-button-primary {
    background-color: #722257;
    color: #fff;
    border: 1px solid #fff;
}

.uk-button-primary:hover {
    background-color: #000000;
    color: #fff;
}

.uk-button-primary:active {
    background-color: #4b1639;
    color: #fbf8f5;
}

.uk-button-default:disabled,
.uk-button-primary:disabled {
    background-color: transparent;
    color: #999;
    border-color: #e5e5e5;
}

.uk-button-group {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle;
    position: relative;
}

.uk-button-group>div:nth-child(n+2) .uk-button {
    margin-left: -1px;
}

.uk-button-group .uk-button:hover,
.uk-button-group .uk-button:active {
    position: relative;
    z-index: 1;
}

.uk-section {
    display: flow-root;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding-top: 40px;
    padding-bottom: 40px;
}

@media (min-width: 960px) {
    .uk-section {
        padding-top: 70px;
        padding-bottom: 70px;
    }
}

.uk-section> :last-child {
    margin-bottom: 0;
}

.uk-container {
    display: flow-root;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
}

@media (min-width: 640px) {
    .uk-container {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media (min-width: 960px) {
    .uk-container {
        padding-left: 40px;
        padding-right: 40px;
    }
}

.uk-container> :last-child {
    margin-bottom: 0;
}

.uk-container-xlarge {
    max-width: 1600px;
}

.uk-container-expand {
    max-width: none;
}

.uk-close {
    color: #999;
    -webkit-transition: 0.1s ease-in-out;
    -o-transition: 0.1s ease-in-out;
    transition: 0.1s ease-in-out;
    -webkit-transition-property: color, opacity;
    -o-transition-property: color, opacity;
    transition-property: color, opacity;
}

.uk-close:hover {
    color: #722257;
}

.uk-alert {
    position: relative;
    margin-bottom: 20px;
    padding: 15px 29px 15px 15px;
    background: #f8f8f8;
    color: #722257;
}

.uk-alert> :last-child {
    margin-bottom: 0;
}

.uk-alert-close {
    position: absolute;
    top: 20px;
    right: 15px;
    color: inherit;
    opacity: 0.4;
}

.uk-alert-close:first-child+* {
    margin-top: 0;
}

.uk-alert-close:hover {
    color: inherit;
    opacity: 0.8;
}

.uk-overlay {
    padding: 30px 30px;
}

.uk-overlay> :last-child {
    margin-bottom: 0;
}

.uk-overlay-default {
    background: #ffffff;
    background: rgba(255, 255, 255, .8);
}

.uk-accordion {
    padding: 0;
    list-style: none;
}

.uk-accordion> :nth-child(n+2) {
    margin-top: 20px;
}

.uk-accordion-title {
    display: block;
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 1.4;
    color: #333;
    overflow: hidden;
}

.uk-accordion-title:before {
    content: "";
    width: 1.4em;
    height: 1.4em;
    margin-left: 10px;
    float: right;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23722257%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23722257%22%20width%3D%221%22%20height%3D%2213%22%20x%3D%226%22%20y%3D%220%22%20%2F%3E%0A%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.uk-open>.uk-accordion-title:before {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23722257%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

.uk-accordion-title:hover {
    color: #722257;
    text-decoration: none;
}

.uk-accordion-content {
    display: flow-root;
    margin-top: 20px;
}

.uk-accordion-content> :last-child {
    margin-bottom: 0;
}

.uk-dropdown {
    display: none;
    position: absolute;
    z-index: 1020;
    --uk-position-offset: 10px;
    --uk-position-viewport-offset: 15px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    min-width: 200px;
    padding: 25px;
    background: #fff;
    color: #722257;
    -webkit-box-shadow: 0 5px 12px rgba(0, 0, 0, .15);
    box-shadow: 0 5px 12px rgba(0, 0, 0, .15);
}

.uk-dropdown {
    z-index: 1 !important;
}

.uk-dropdown> :last-child {
    margin-bottom: 0;
}

.uk-dropdown-nav {
    font-size: 14px;
    font-size: 0.875rem;
}

.uk-dropdown-nav>li>a {
    color: #000000;
}

.uk-dropdown-nav>li>a:hover {
    color: #722257;
}

.uk-dropdown-nav .uk-nav-divider {
    border-top: 1px solid #e5e5e5;
}

.uk-modal {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1010;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 15px 15px;
    background: #000000;
    background: rgba(0, 0, 0, .6);
    opacity: 0;
    -webkit-transition: opacity 0.15s linear;
    -o-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
}

@media (min-width: 640px) {
    .uk-modal {
        padding: 50px 30px;
    }
}

@media (min-width: 960px) {
    .uk-modal {
        padding-left: 40px;
        padding-right: 40px;
    }
}

.uk-modal-dialog {
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0 auto;
    width: 600px;
    max-width: 100% !important;
    background: #fff;
    opacity: 0;
    -webkit-transform: translateY(-100px);
    -ms-transform: translateY(-100px);
    transform: translateY(-100px);
    -webkit-transition: 0.3s linear;
    -o-transition: 0.3s linear;
    transition: 0.3s linear;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    -o-transition-property: opacity, transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
}

.uk-modal-body {
    display: flow-root;
    padding: 20px 20px;
}

@media (min-width: 640px) {
    .uk-modal-body {
        padding: 30px 30px;
    }
}

.uk-modal-body> :last-child {
    margin-bottom: 0;
}

[class*='uk-modal-close-'] {
    position: absolute;
    z-index: 1010;
    top: 10px;
    right: 10px;
    padding: 5px;
}

[class*='uk-modal-close-']:first-child+* {
    margin-top: 0;
}

.uk-sticky {
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.uk-sticky-placeholder {
    pointer-events: none;
}

.uk-leader {
    overflow: hidden;
}

.uk-leader-fill:after {
    display: inline-block;
    margin-left: 15px;
    width: 0;
    content: attr(data-fill);
    white-space: nowrap;
}

.uk-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
}

.uk-grid>* {
    margin: 0;
}

.uk-grid>*> :last-child {
    margin-bottom: 0;
}

.uk-grid {
    margin-left: 0px;
}

.uk-grid>* {
    padding-left: 0px;
}

.uk-grid>.uk-grid-margin,
*+.uk-grid-margin {
    margin-top: 0px;
}

@media (min-width: 1200px) {
    .uk-grid {
        margin-left: -40px;
    }

    .uk-grid>* {
        padding-left: 40px;
    }

    .uk-grid>.uk-grid-margin,
    *+.uk-grid-margin {
        margin-top: 40px;
    }
}

.uk-grid-small {
    margin-left: -15px;
}

.uk-grid-small>* {
    padding-left: 15px;
}

.uk-grid-small>.uk-grid-margin {
    margin-top: 15px;
}

.uk-nav {
    margin: 0;
    padding: 0;
    list-style: none;
}

.uk-nav li>a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-column-gap: 0.25em;
    -moz-column-gap: 0.25em;
    column-gap: 0.25em;
    text-decoration: none;
}

.uk-nav>li>a {
    padding: 5px 0;
}

.sort-by .uk-nav>li>a:hover {
    color: #722257 !important;
}

.uk-nav .uk-nav-divider {
    margin: 5px 0;
}

.uk-iconnav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: -10px;
}

.uk-iconnav>* {
    padding-left: 10px;
}

.uk-iconnav>*>a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-column-gap: 0.25em;
    -moz-column-gap: 0.25em;
    column-gap: 0.25em;
    line-height: 0;
    color: #999;
    text-decoration: none;
    font-size: 14px;
    font-size: 0.875rem;
    -webkit-transition: 0.1s ease-in-out;
    -o-transition: 0.1s ease-in-out;
    transition: 0.1s ease-in-out;
    -webkit-transition-property: color, background-color;
    -o-transition-property: color, background-color;
    transition-property: color, background-color;
}

.uk-iconnav>*>a:hover {
    color: #722257;
}

.uk-iconnav-vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-left: 0;
    margin-top: -10px;
}

.uk-iconnav-vertical>* {
    padding-left: 0;
    padding-top: 15px;
}

[class*='uk-child-width']>* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
}

.uk-child-width-auto>* {
    width: auto;
}

@media (min-width: 640px) {
    .uk-child-width-1-1\@s>* {
        width: 100%;
    }

    .uk-child-width-1-2\@s>* {
        width: 50%;
    }
}

@media (min-width: 960px) {
    .uk-child-width-1-2\@m>* {
        width: 50%;
    }

    .uk-child-width-1-3\@m>* {
        width: 33.32223%;
    }

    .uk-child-width-1-6\@m>* {
        width: 16.66389%;
    }
}

[class*='uk-width'] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
}

.uk-width-auto {
    width: auto;
}

.uk-width-expand {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    min-width: 1px;
}

@media (min-width: 960px) {
    .uk-width-1-3\@m {
        width: 33.32223%;
    }

    .uk-width-expand\@m {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        min-width: 1px;
    }
}

@media (min-width: 1200px) {
    .uk-width-1-4\@l {
        width: 25%;
    }

    .uk-width-3-4\@l {
        width: 75%;
    }
}

@media (min-width: 1600px) {
    .uk-width-1-4\@xl {
        width: 25%;
    }
}

[class*='uk-height'] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.uk-height-medium {
    height: 300px;
}

.uk-text-left {
    text-align: left !important;
}

.uk-text-right {
    text-align: right !important;
}

.uk-text-center {
    text-align: center !important;
}

@media (min-width: 960px) {
    .uk-text-right\@m {
        text-align: right !important;
    }
}

.uk-background-cover {
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.uk-background-cover {
    -webkit-background-size: cover;
    background-size: cover;
}

.uk-svg,
.uk-svg:not(.uk-preserve) [fill*='#']:not(.uk-preserve) {
    fill: currentcolor;
}

.uk-svg {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
}

.uk-display-block {
    display: block !important;
}

.uk-display-inline {
    display: inline !important;
}

[class*='uk-inline'] {
    display: inline-block;
    position: relative;
    max-width: 100%;
    vertical-align: middle;
    -webkit-backface-visibility: hidden;
}

.uk-inline-clip {
    overflow: hidden;
}

.uk-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.uk-flex-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.uk-flex-middle {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.uk-margin {
    margin-bottom: 20px;
}

.uk-margin-small-bottom {
    margin-bottom: 10px !important;
}

.uk-margin-medium-top {
    margin-top: 40px !important;
}

.uk-margin-large {
    margin-bottom: 40px;
}

*+.uk-margin-large {
    margin-top: 40px !important;
}

@media (min-width: 1200px) {
    .uk-margin-large {
        margin-bottom: 70px;
    }

    *+.uk-margin-large {
        margin-top: 70px !important;
    }
}

.uk-margin-remove {
    margin: 0 !important;
}

.uk-padding-small {
    padding: 15px;
}

.uk-padding-remove {
    padding: 0 !important;
}

[class*='uk-position-top'],
[class*='uk-position-bottom'] {
    position: absolute !important;
    max-width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.uk-position-top-left {
    top: 0;
    left: 0;
}

.uk-position-top-right {
    top: 0;
    right: 0;
}

.uk-position-bottom-left {
    bottom: 0;
    left: 0;
}

.uk-position-bottom-right {
    bottom: 0;
    right: 0;
}

.uk-position-z-index {
    z-index: 1;
}

:where([class*='uk-transition-slide']) {
    --uk-position-translate-x: 0;
    --uk-position-translate-y: 0;
}

[class*='uk-transition-slide'] {
    --uk-translate-x: 0;
    --uk-translate-y: 0;
    --uk-scale-x: 1;
    --uk-scale-y: 1;
    -webkit-transform: translate(var(--uk-position-translate-x), var(--uk-position-translate-y)) translate(var(--uk-translate-x), var(--uk-translate-y)) scale(var(--uk-scale-x), var(--uk-scale-y));
    -ms-transform: translate(var(--uk-position-translate-x), var(--uk-position-translate-y)) translate(var(--uk-translate-x), var(--uk-translate-y)) scale(var(--uk-scale-x), var(--uk-scale-y));
    transform: translate(var(--uk-position-translate-x), var(--uk-position-translate-y)) translate(var(--uk-translate-x), var(--uk-translate-y)) scale(var(--uk-scale-x), var(--uk-scale-y));
    -webkit-transition: 0.3s ease-out;
    -o-transition: 0.3s ease-out;
    transition: 0.3s ease-out;
    -webkit-transition-property: opacity, -webkit-transform, -webkit-filter;
    transition-property: opacity, -webkit-transform, -webkit-filter;
    -o-transition-property: opacity, transform, filter;
    transition-property: opacity, transform, filter;
    transition-property: opacity, transform, filter, -webkit-transform, -webkit-filter;
    opacity: 0;
}

.uk-transition-slide-bottom-small {
    --uk-translate-y: 10px;
}

.uk-transition-toggle:hover [class*='uk-transition-slide'],
.uk-transition-toggle:focus [class*='uk-transition-slide'] {
    --uk-translate-x: 0;
    --uk-translate-y: 0;
    opacity: 1;
}

@media (max-width: 1200px) {
    .uk-transition-toggle [class*='uk-transition-slide'] {
        opacity: 1;
    }

    .uk-transition-slide-bottom-small {
        --uk-translate-y: 10px !important;
    }
}

[hidden] {
    display: none !important;
}

@media (min-width: 1200px) {
    .uk-hidden\@l {
        display: none !important;
    }
}

@media (max-width: 1199px) {
    .uk-visible\@l {
        display: none !important;
    }
}

.uk-light {
    color: #fbf8f5;
    color: rgba(251, 248, 245, .7);
}

.uk-light a {
    color: #fbf8f5;
}

.uk-light a:hover {
    color: #fbf8f5;
}

.uk-light h3 {
    color: #fbf8f5;
}

.uk-light :focus {
    outline-color: #fbf8f5;
}

.uk-light .uk-radio {
    background-color: #fbf8f5;
    background-color: rgba(251, 248, 245, .1);
    border-color: #fbf8f5;
    border-color: rgba(251, 248, 245, .2);
}

.uk-light .uk-radio:focus {
    background-color: #fbf8f5;
    background-color: rgba(251, 248, 245, .15);
    border-color: #fbf8f5;
    border-color: rgba(251, 248, 245, .7);
}

.uk-light .uk-radio:checked {
    background-color: #fbf8f5;
    border-color: #fbf8f5;
    border-color: rgba(251, 248, 245, .7);
}

.uk-light .uk-radio:checked:focus {
    background-color: #fbf8f5;
}

.uk-light .uk-radio:checked {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23722257%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

.uk-light .uk-iconnav>*>a {
    color: #fbf8f5;
    color: rgba(251, 248, 245, .5);
}

.uk-light .uk-iconnav>*>a:hover {
    color: #fbf8f5;
    color: rgba(251, 248, 245, .7);
}

@media print {

    *,
    *:before,
    *:after {
        background: transparent !important;
        color: black !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}

::-moz-selection {
    background: #722257 !important;
    text-shadow: none;
}

::selection {
    background: #722257 !important;
    text-shadow: none;
}

.uk-grid {
    margin-left: 0;
}

h2 {
    color: #505050 !important;
    padding: 20px 0;
    margin: 0 !important;
    font-family: bigCalsonMedium !important;
}

p {
    color: #505050;
    font-size: 16px;
    font-family: gillSans;
}

.uk-svg,
.uk-svg:not(.uk-preserve) [fill*='#']:not(.uk-preserve) {
    fill: #722257;
}

.uk-divider-icon {
    position: relative;
    height: 20px;
    background-image: url("/assets/images/new_template/other_pages/diamond-ico.png");
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.listing-page-content>a {
    font-size: 15px;
    color: #000 !important;
    font-family: gillSans !important;
}

.best-seller {
    padding: 3px 20px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    background: -webkit-gradient(linear, left top, right top, from(#020024), color-stop(0%, #722257), to(#39112c));
    background: -webkit-linear-gradient(left, #020024 0%, #722257 0%, #39112c 100%);
    background: -o-linear-gradient(left, #020024 0%, #722257 0%, #39112c 100%);
    background: linear-gradient(90deg, #020024 0%, #722257 0%, #39112c 100%);
}

.side-bar-listing .new-arrivals button {
    border: none;
    text-align: left;
    padding: 0;
}

.side-bar-listing .new-arrivals hr {
    margin-top: 0;
}

.side-bar-listing .uk-accordion-title {
    font-size: 14px !important;
    text-transform: uppercase;
    color: #000000 !important;
    font-family: gillSans !important;
    border-bottom: 1px solid rgba(204, 204, 204, 0.5098);
}

.side-bar-listing .uk-fieldset label {
    color: #000000;
    margin: 4px 0;
    font-family: gillSans !important;
}

.side-bar-listing .uk-fieldset input.uk-checkbox {
    margin-right: 5px;
}

.side-bar-listing .range-slider {
    width: 100%;
    text-align: center;
    position: relative;
}

.side-bar-listing .range-slider .rangeValues {
    display: block;
}

.side-bar-listing .range-slider input[type=range] {
    -webkit-appearance: none;
    border: 1px solid white;
    width: 100%;
    position: absolute;
    left: 0;
}

.side-bar-listing .range-slider input[type=range]:focus {
    outline: none;
}

.side-bar-listing .range-slider input[type=range]:-moz-focusring {
    outline: 1px solid white;
    outline-offset: -1px;
}

.listing-box-main h3 {
    margin: 10px auto !important;
    color: #000000;
    text-align: left;
    text-transform: uppercase;
    font-size: 18px;
    max-width: 100%;
    background: #fff !important;
    position: relative;
    font-family: gillSans;
}

@media (max-width: 768px) {
    .listing-box-main h3 {
        margin: 30px auto;
    }
}

.listing-box-main h3:after {
    content: "";
    display: block;
    width: 75%;
    height: 1px;
    background: #969696;
    left: 25%;
    top: 50%;
    position: absolute;
}

@media (max-width: 639px) {
    .listing-box-main h3:after {
        width: 50%;
        left: 80% !important;
    }
}

@media only screen and (min-device-width: 640px) and (max-device-width: 1024px) {
    .listing-box-main h3:after {
        width: 50%;
        left: 40% !important;
    }
}

.listing-box-main .color-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.listing-box-main .color-button button {
    border: none;
    padding: 0px !important;
    font-size: 19px;
    height: 30px;
    width: 30px;
    -webkit-box-shadow: 0 2px 5px #b7b7b7;
    box-shadow: 0 2px 5px #b7b7b7;
    cursor: pointer;
    margin-bottom: 5px;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.listing-box-main .color-button button:active {
    -webkit-box-shadow: 0 0 2px darkslategray;
    box-shadow: 0 0 2px darkslategray;
    -webkit-transform: translateY(2px);
    -ms-transform: translateY(2px);
    transform: translateY(2px);
}

.listing-box-main .color-button button.active {
    box-shadow: 0 0 2px darkslategray;
    background-repeat: no-repeat;
    border: 2px solid #722257;
}

.listing-box-main .color-button button.active:after {
    top: 10px;
    right: 4px;
    height: 15px;
    width: 15px;
    background-size: 70%;
    background-repeat: no-repeat;
    position: absolute;
}


.listing-box-main .color-button .round-1 {
    -webkit-border-radius: 70%;
    border-radius: 70%;
    background-color: #FFD700;
}

.listing-box-main .color-button .round-2 {
    -webkit-border-radius: 70%;
    border-radius: 70%;
    background-color: #F6CFCA;
}

.listing-box-main .color-button .round-3 {
    -webkit-border-radius: 70%;
    border-radius: 70%;
    background-color: #f2f2f2;
}

.listing-box-main .listing-page-content {
    color: #000000;
    padding: 20px 15px !important;
}

.listing-box-main .listing-page-content del {
    color: #ccc;
}

.select-your-favroute {
    margin-top: 50px;
}

.select-your-favroute h3 {
    font-size: 38px;
    color: #000000;
    font-weight: 500;
    line-height: 40px;
    font-family: Big Caslon, Medium;
}

@media (max-width: 768px) {
    .select-your-favroute h3 {
        font-size: 22px;
        line-height: 22px;
    }
}

.select-your-favroute .uk-overlay {
    top: 12px;
    width: 99%;
    height: 92%;
    background-color: #fbf8f5;
    opacity: 0.8;
}

.select-your-favroute p.like-text {
    font-size: 24px;
    color: #000000;
    font-weight: 600;
}

@media (max-width: 768px) {
    .select-your-favroute p.like-text {
        font-size: 16px;
    }
}

.sd-instafeed img {
    width: 100%;
    height: 100%;
}

@media (max-width: 768px) {
    .sd-instafeed img {
        padding: 0 15px;
    }
}

.sd-instafeed .uk-first-column {
    padding: 0;
}

.uk-modal a.uk-accordion-title {
    font-size: 16px !important;
}

.clear-all-text button.uk-button-primary {
    background: #fff;
    color: #000;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
}

.uk-alert {
    position: relative;
    margin-bottom: 20px;
    padding: 5px 20px 5px 20px !important;
    background: #f8f8f8;
    color: #722257;
    margin: 2px;
    -webkit-border-radius: 20px;
    border-radius: 5px !important;
}

.uk-alert .uk-alert-close {
    position: absolute;
    top: 17px;
    right: 11px;
    color: inherit;
    opacity: 0.4;
    width: 10px;
}

@media (max-width: 1200px) {

    .listing-page-content>a {
        font-size: 12px;
    }

    .uk-grid>* {
        padding-left: 5px;
    }
}

@media (max-width: 768px) {
    .sd-inner-content {
        padding: 0;
    }

    *+[class*='uk-divider'] {
        margin-top: 5px;
    }

    h2 {
        font-size: 20px;
        padding: 10px 0;
    }

    .sd-inner-content {
        padding-top: 15px;
    }

    .listing-box-main .uk-grid-small>* {
        padding-left: 0;
    }

    .sort-by {
        padding-top: 20px;
    }
}

/*! CSS Used from: Embedded ; media=all */
@media all {
    [class*='line-'] {
        transition: 0.2s ease-in-out;
        transition-property: transform, opacity, ;
        transform-origin: center;
        opacity: 1;
    }
}

a:before {
    position: initial !important;
    top: 2rem;
    height: 2px;
    width: 0;
    background-color: rgb(255, 255, 255) !important;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
}

.uk-accordion>li {
    display: initial;
}

p.uk-margin-remove {
    color: #505050 !important;
    font-size: 16px;
    font-family: gillSans !important;
}

.uk-transition-toggle .discover-img img {
    max-width: 100%;
    height: auto;
    box-sizing: border-box;
    transform: scale(1.1);
}

.uk-transition-toggle .discover-img {
    width: 100%;
    max-width: 100%;
    background: #fbf8f5;
    background-blend-mode: saturation;
}

.uk-first-column .uk-sticky {
    position: sticky !important;
    top: 14px;
    z-index: 9 !important;
}

.uk-first-column .filter-text {
    color: #722257
}

.sort-by .uk-button-default {
    min-width: 200px;
}

.sort-by .uk-dropdown.uk-open {
    top: 50px !important;
}

.store-locator,
.store-locator .uk-button-default {
    width: 100%;
}

.store-locator .uk-dropdown.uk-open {
    top: 50px !important;
}