.header {
  display: grid;
  justify-content: space-between;
  grid-template-columns: auto auto auto;
  margin: 0 auto;
  max-width: 1618px;
  padding: 20px 20px 20px;
}

.top-text {
  background-color: #722257;
  padding: 5px;
}

.top-text span {
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-family: gillSans;
  display: block;
}

.top-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1606px;
  margin: 0 auto;
}

.head-contact-detail a span {
  color: #fff;
  text-align: left;
  display: inline-block;
  vertical-align: -webkit-baseline-middle;
}

/* .logo {
  grid-column: 1;
} */

input#search {
  max-width: 100%;
  min-width: 100% !important;
}

.search-links {
  align-items: self-end;
}

.topnav li:last-child {
  margin: 0;
}

.topnav li {
  margin-right: 30px;
  display: inline-block;
  cursor: pointer;
  z-index: 10;
}

.head-contact-detail {
  max-width: 376px;
  display: grid;
  grid-template-columns: auto auto auto;
  width: 100%;
  justify-content: space-between;
}

.search-links a {
  line-height: 24px;
  height: 24px;
}

a.cart-link {
  border-left: 1px solid #722257;
  padding-left: 20px;
  display: inline-block;
  vertical-align: middle;
}

.top-info-bar {
  background-color: #722257;
}

.people-section .nav-btn {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #722257;
  display: inline-block;
  position: relative;
}

.people-section .owl-theme .owl-nav [class*="owl-"] {
  background: 0 0;
  padding: 0;
  border-radius: 0;
}

.people-section .nav-btn::after {
  content: "";
  width: 7px;
  height: 14px;
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.people-section .prev-btn::after {
  background-image: url("/assets/images/new_template/images/left.svg");

}

.people-section .next-btn::after {
  background-image: url("/assets/images/new_template/images/right.svg");
}

section.gift-ideas-section {
  background-color: #fbf8f5;
  padding: 70px 20px 40px;
  margin-bottom: 90px;
}

.gift-ideas-container .heading-section {
  max-width: 157px;
}

.gift-ideas-container {
  max-width: 1640px;
  margin: 0 auto;
}

.gift-content {
  display: flex;
  flex-wrap: wrap;
  margin-top: 37px;
}

.gift-content a {
  width: 100%;
  padding: 20px;
  border: 1px solid #722257;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 50px;
  transition: 0.3s;
  font-size: 21px;
  vertical-align: middle;
  height: 61px;
  max-width: 22.56%;
}

.gift-content a:hover {
  background-color: #722257;
  color: #fff;
}

.promise-section {
  background-color: #fbf8f5;
  padding: 70px 20px;
  margin-bottom: 80px;
}

.promise-container .heading-section {
  max-width: 365px;
  margin: 0 auto;
}

.promise-container {
  max-width: 1547px;
  margin: 0 auto;
}

.promise-content {
  display: flex;
  flex-wrap: wrap;
  margin: 37px -15px 0;
  justify-content: center;
}

.promise-content img {
  width: 90px;
  height: 55px;
}

.promise-content a {
  text-align: center;
  border: 1px solid #b68a2d;
  box-shadow: 2px 4px 5px #b68a2d;
  padding: 30px;
  vertical-align: middle;
  max-height: 180px;
  font-size: 12px;
  max-width: 165px;
  width: 100%;
  margin: 0 15px 30px;
  transition: all 0.3s ease-in-out;
}

.promise-content a:hover {
  -webkit-transform: scale(1.08);
  transform: scale(1.1);
  border: 0;
  box-shadow: 0 0 6px rgba(0, 0%, 0%, 8%);
}

.promise-content span {
  display: block;
  width: 100px;
  margin: 25px auto 0;
}

.people-container .heading-section {
  max-width: 266px;
  margin: 0 auto;
  margin-bottom: 40px;
}

.people-container {
  max-width: 1640px;
  margin: 0 auto;
}

.people-says-content {
  display: flex;
  justify-content: space-between;
}

.item.what-says span.review-text {
  max-width: 453px;
  display: block;
  margin: 20px 0;
  color: #505050;
  font-size: 15px;
  height: 55px;
  overflow: hidden;
}

.owl-carousel .owl-item img {
  display: block;
  width: auto;
}



.what-says {
  background: #fff;
  padding: 20px;
  box-shadow: 0 3px 6px #00000029;
  margin: 5px;
  border-radius: 10px;
}

span.review-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

span.review-details-inner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

span.name-details {
  margin-left: 15px;
  color: #505050;
  font-size: 16px;
}

span.month-details {
  margin-top: 5px;
  display: block;
  color: #505050;
  font-size: 10px;
}

.fa {
  font: normal normal normal 14px/1 FontAwesome;
}

.fa-angle-left {
  background: #722257;
  color: #fff;
  font-size: 30px;
  border: 1px solid #722257;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fa-angle-left:before {
  position: relative;
  right: 1px;
  top: -1px;
}

.fa-angle-left:before {
  content: "\f104";
}

.fa-angle-right {
  background: #722257;
  color: #fff;
  font-size: 30px;
  border: 1px solid #722257;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.owl-theme .owl-nav [class*="owl-"],
.owl-theme .owl-nav [class*="owl-"]:hover {
  background: 0 0;
}

.people-section button.owl-prev {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  padding: 0 !important;
  margin: 0 !important;
}

.people-section button.owl-next {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0 !important;
  margin: 0 !important;
}

.people-section .owl-carousel {
  padding: 0 40px;
}

#cover {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 5;
  width: 100%;
  height: 100%;
  display: none;
}

#people-says-popup {
  height: 380px;
  max-width: 820px;
  margin: 0 auto;
  position: relative;
  z-index: 10;
  display: none;
  overflow: hidden;
}

#people-says-popup:target,
#people-says-popup:target+#cover {
  display: block;
  opacity: 2;
}

.cancel {
  display: block;
  position: absolute;
  top: 0;
  right: 2px;
  color: #fff;
  height: 30px;
  width: 35px;
  font-size: 30px;
  text-decoration: none;
  text-align: center;
  font-weight: 700;
  z-index: 9999;
}

.people-says-pop-up .item.what-says {
  display: flex;
  flex-direction: column;
  border-radius: 0;
}

.people-says-pop-up .item.what-says>img {
  width: 142px;
  margin: 0 auto;
}

.people-says-pop-up span.review-details {
  order: -1;
  border-bottom: 1px solid #e3e3e3;
  margin: 0 -20px 20px;
  padding: 0 20px 10px;
}

.people-says-pop-up span.name-details {
  text-align: left;
}

.people-says-pop-up .item.what-says span.review-text {
  margin: 20px auto;
  max-width: none;
  height: auto;
}

.social-google {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e3e3e3;
  margin: 0 -20px;
  padding: 15px 20px 0;
  align-items: center;
}

.social-google a {
  display: inline-block;
  color: #505050;
}

@media screen and (max-width: 1300px) {

  .topnav li,
  a.dropbtn {
    font-size: 12px;
  }

  /* .topnav li {
    margin-right: 35px;
  } */

  .header {
    padding: 0;
  }

  .search-box {
    position: relative;
    margin: 0 !important;
    grid-column: 2;
    grid-row: 2;
  }

  .search-links {
    align-items: center;
    grid-row: 2;
    justify-content: end;
  }

  .logo {
    grid-column: 1 / span 2;
    width: 123px;
    grid-row: 1 / span 3;
  }

  .logo img+img {
    margin-top: 5px;
  }

  .topnav {
    grid-row: 4;
  }

  .gift-content a {
    font-size: 16px;
  }

  .top-text {
    max-width: 100%;
  }
}

@media screen and (max-width: 1023px) {
  .top-text span {
    font-size: 12px;
  }

  .top-text>* {
    max-width: 49%;
  }

  .head-contact-detail {
    justify-content: start;
  }

  .head-contact-detail a {
    margin-left: 15px;
  }

  .head-contact-detail a:first-child {
    margin-left: 0;
  }
}

@media screen and (max-width: 1024px) {
  .logo {
    order: -1;
    margin: 0 auto 10px;
    width: 123px;
  }

  .search-box {
    width: 100%;
  }

  .search-links {
    position: absolute;
    right: 0;
    top: auto;
  }

  .topnav li {
    margin-right: 0;
    display: block;
  }

  a.cart-link {
    border-left: none;
    padding-left: 0;
  }

  .top-text {
    flex-direction: column;
  }

  .head-contact-detail {
    order: 2;
    margin-top: 15px;
  }

  .top-text>* {
    max-width: 100%;
    justify-content: space-around;
  }

  .head-contact-detail a {
    margin-left: 8px;
  }
}

@media screen and (min-width: 768px) {
  .gift-content a:not(:first-child) {
    margin-left: 3.25%;
  }

  .gift-content a:nth-child(5n + 5) {
    margin-left: 0;
  }
}

@media screen and (min-width: 600px) and (max-width: 767px) {
  .gift-content a {
    max-width: 30%;
    margin-left: 5%;
  }

  .gift-content a:nth-child(3n + 1) {
    margin-left: 0;
  }

  .gift-content a:nth-child(5n + 5) {
    margin-left: 5%;
  }
}

@media screen and (max-width: 599px) {
  .gift-content a {
    max-width: 100%;
  }

  .promise-content a {
    max-width: 152px;
  }
}

.cart-separation {
  border-left: 1px solid #722257;
  display: inline-block;
  vertical-align: middle;
}

.show-div.shopping-cart {
  padding: -2px 15px;
  min-width: 350px;
  left: unset;
  right: -10px;
  top: 35px;
  max-height: 410px;
  overflow-y: auto;
}

.onhover-div:hover .show-div {
  opacity: 1;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  visibility: visible;
}

.onhover-div .show-div {
  top: 40px;
  position: absolute;
  z-index: 9;
  background-color: #FFF;
  -webkit-transition: all linear 0.3s;
  transition: all linear 0.3s;
  /* min-width: 236px; */
  text-align: left;
  -webkit-box-shadow: 0 0 1px 0 #ebebeb;
  box-shadow: 0 0 1px 0 #ebebeb;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  right: 0;
  padding: 0;
  border: #000000;
  border-color: #000000;
  border-radius: 1 !important;
}

.onhover-div .show-div.shopping-cart li {
  position: relative;
  padding-bottom: 10px;
  width: 100%;
}

.onhover-div .show-div li {
  padding: 0;
}

.main-menu .menu-right .icon-nav li {
  padding-left: 20px;
}

.onhover-div .show-div.shopping-cart li .media img {
  height: 90px;
}

.onhover-div img,
.onhover-div i {
  cursor: pointer;
}

.onhover-div .show-div.shopping-cart li .media .media-body {
  -ms-flex-item-align: center;
  align-self: center;
}

.media-body {
  margin-top: 20px;
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .close-circle {
  position: absolute;
  top: 0;
  right: 0;
}

.onhover-div .show-div.shopping-cart li .close-circle i {
  color: #a1a1a1;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .total {
  border-top: 1px solid #f1f5f4;
  border-bottom: 1px solid #f1f5f4;
  padding-top: 10px;
  padding-bottom: 10px;
}

.onhover-div .show-div.shopping-cart li .total h5 {
  text-transform: capitalize;
  margin-bottom: 0;
  color: #999999;
}

.onhover-div .show-div.shopping-cart li:last-child {
  padding-bottom: 0;
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .buttons a {
  font-size: 16px;
  color: #333333;
  text-transform: capitalize;
  font-weight: 700;
}

.onhover-div .show-div.shopping-cart li .buttons .checkout {
  float: right;
}

.onhover-div .show-div.shopping-cart li .buttons a {
  font-size: 16px;
  color: #333333;
  text-transform: capitalize;
  font-weight: 700;
}

@media screen and (max-width: 577px) {
  .search-box {
    display: none;
  }

  .search-links .account {
    display: none;
  }

  .search-links .cart {
    display: none;
  }
}

.mobile-fix-option {
  display: none;
}

@media (max-width: 959px) {
  .mobile-fix-option {
    position: fixed;
    bottom:  4px;
    left: 5px;
    height: 60px;
    background-color: #ffffff;
    width: 98vw;
    z-index: 9;
    -webkit-box-shadow: 0 2px 5px 0 #a8a8a8;
    box-shadow: 0 2px 5px 0 #a8a8a8;
    /* width: 96%; */
    border-radius: 15px;
  }
}

.media-body h4 {
  font-size: 16px;
  font-family: big-caslon;
  text-transform: capitalize;
  color: rgb(114, 34, 87);
  font-weight: 400;
  letter-spacing: 1px;
}

.total h5 {
  font-family: gillSans;
  font-size: 13.28px;
  font-weight: 400;
  color: rgb(114, 34, 87);
  letter-spacing: 1px;
}

.view-cart a {
  color: #722257;
  font-family: gillSans;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  text-decoration: none;
  background-color: transparent;
}

.checkout a {
  color: #722257;
  font-family: gillSans;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  text-decoration: none;
  background-color: transparent;
}

@media (max-width: 767px) {
  .head-contact-detail {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .header-container .header {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-right: 30px;
    padding: 0 0 10px;
  }

  .topnav .icon {
    position: absolute;
    left: 0;
    top: 5px;
    width: 17px;
  }
}

.topnav .icon {
  display: none;
}

.sticky-panel .uk-sticky-fixed {
  position: fixed !important;
  top: 0px !important;
  box-shadow: 0px 0px 15px -5px #888888
}

.sticky-panel {
  box-shadow: 0px 0px 15px -5px #888888
}

.back-drop-search {
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99999999;
  background-color: #10101024;
  backdrop-filter: blur(3px);
  color: white;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
}