/* @media screen and (max-width: 1024px) {
    disabled as it effects sliders li elements on mobile display.
    li {
        display: block !important;
    }
} */
@media screen and (max-width: 1024px) {
    #offcanvas-nav-primary * li {
        display: block !important;
        z-index: 33333;
    }
}

#offcanvas-nav-primary{
    z-index: 33333;
}


ul.uk-accordion-menu-bar p.none-link {
    font-size: 18px;
    color: #fff !important;
}

.uk-offcanvas-bar .uk-accordion-title {
    color: #fff !important;
}

.uk-dropdown-nav>li>a {
    color: #999 !important;
}

.uk-offcanvas-bar .uk-accordion-title::before {
    position: initial;
}

a.side-bar::before {
    top: 2rem;
    background-color: rgb(114, 34, 87) !important;
    transition: 0.5s ease;
}

.uk-accordion-menu-bar .uk-offcanvas-close {
    position: inherit !important;
    padding: 0 !important;
}

.diamondstand-icon {
    background: url(/assets/images/sunny/25.png) no-repeat 50%;
    background-size: contain;
    width: 20px;
    height: 20px;
    display: inline-block;
    margin: 0 10px;
}

.sidemenubar-hr-line {
    width: calc((100% - 46px) / 2);
    border-top: 1px solid #fff;
    opacity: 1;
    height: 4px;
}

p.url-active,
a.url-active {
    border-bottom: 1px solid #fff
}