.badge {
	box-sizing: border-box;
	top: -9px;
    right: -16px;
	min-width: 18px;
	height: 15px;
	padding: 0 0px;
	vertical-align: middle;
	color: #722257 !important;
	font-size: 12px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	line-height: 0;
}

.back-drop-search {
	position: fixed;
	margin: auto;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 99999999;
	background-color: #10101024;
	backdrop-filter: blur(3px);
	color: white;
	display: flex;
	/* justify-content: center; */
	/* align-items: center; */
	flex-direction: column;
  }