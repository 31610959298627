.discover-homepage .discover-item .discover-img {
    width: 100%;
    max-width: 100%;
    background: #fbf8f5;
    background-blend-mode: saturation;
}

.discover-homepage .discover-item .discover-img img {
    mix-blend-mode: multiply;
}

.discover-diamond .discover-item .discover-img img {
    display: block;
    margin: auto;
}

.searchRefult {
    width: 80%;
    margin: 40px auto 0;
    display: flex;
    align-items: stretch;
    justify-content: center;
    gap: 32px;
    position: relative;
}

@media (max-width: 991px) {
    .searchRefult {
        gap: 14px;
        justify-content: flex-start;
        align-items: center;
        overflow-x: scroll;
        width: 100%;
        padding-left: 0px;
    }

    .searchRefult--list img {
        flex: 0 0 140px;
        max-width: 140px;
    }
}