.topnav .icon {
    display: none;
}

.topnav .dropdown-content {
    display: none;
    position: fixed;
    background-color: #f9f9f9;
    min-width: 360px;
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
    z-index: 1;
    width: 100%;
    left: -280PX;
    max-width: 733px;
    right: 0;
    margin: 0 auto;
    /* height: 100%; */
}

.topnav .dropdown-content a {
    float: none;
    color: #000 !important;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
    font-family: gillSans, sans-serif !important;
}

a.heading {
    color: #722257 !important;
}

.topnav .dropdown:hover .dropdown-content.dropdown-content_links {
    display: block;
}

.topnav .dropdown>a {
    text-decoration: none;
    color: #000 !important;
    font-family: gillSans, sans-serif !important;
}


.topnav>ul>li>a {
    text-decoration: none;
    color: #000 !important;
    font-family: gillSans, sans-serif !important;
    transition: all .2s ease-in-out;
}

.topnav>ul>li>a:hover {
    color: #000 !important;
    font-family: gillSans, sans-serif !important;
    font-weight: bold;
    /* transform: scale(1); */
}

.topnav>ul>li>a.ribbon {
    color: #E5E5E5 !important;
}

li.navbar {
    text-decoration: none;
    color: #000 !important;
}

.dropdown-content .heading-section {
    max-width: fit-content;
    width: 100%;
}

.dropdown-content .heading-title {
    font-size: 14px;
    line-height: 30px;
}

.dropdown-content .heading-icon {
    margin-top: 2px;
}

.dropdown-content .diamond-icon {
    width: 12px;
    height: 12px;
    margin: 0 10px;
}

.dropdown-content .hr-line {
    width: calc((100% - 30px) / 2);
}

.menu__list .expand-list li a {
    font-size: 10px;
    line-height: 11px;
    letter-spacing: .5px;
    color: #000;
    margin-left: 10px;
    display: inline-block;
    padding: 0;
    margin-top: 3px;
    transition: .3s ease-in-out;
}

.menu__list .expand-list li a:hover {
    color: #722257;
}

.expand-list img {
    max-width: 40px;
    width: 100%;
}

.col_2_content img {
    width: 12px;
    height: 12px;
}

.menu-list-main {
    display: flex;
}

.menu-list-main .menu__list-col-1.col1_ring {
    border-right: 1px solid #E5E5E5;
    max-width: 45%;
    width: 100%;
}

.menu__list .col_2_content,
.col1_ring {
    padding: 30px 20px;
}

.expand-list,
.menu__list .expand-list li,
.col_1_content,
.col2_ring {
    display: flex;
}

.expand-list {
    flex-direction: column;
    margin-top: 15px;
}

.expand-list li {
    align-items: center;
    margin-bottom: 10px;
}

.dropdown-expand {
    background-color: #fff;
    height: 100%;
}

.menu__list .col_2_content {
    max-width: 45.45%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.menu-list-main .menu__list .col_2_img {
    max-width: 54.55%;
    width: 100%;
}

.menu__list-col-2 li {
    margin-bottom: 22px;
}

.col_2_img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.col_1_content {
    justify-content: space-between;
}

.menu-list-main .menu__list.col2_ring {
    max-width: 55%;
    width: 100%;
    padding: 0;
}

/* RS:: Code changes End */


/* [prakash gurung code changed start ] */

.menu-list-row ul.menu__list-links-title {
    display: flex;
    flex-direction: column;
    max-width: 190px;
    width: 100%;
    /* padding-bottom: 26px; */
    box-shadow: 12px 6px 8px -8px #b3b3b3;
}

.topnav .menu-list-row li,
.topnav .menu-list-row li:last-child {
    margin: 0;
}

.menu-list-row .menu__list-expand {
    display: flex;
    height: 100%;
}

.topnav .menu-list-row .dropdown-content {
    top: 0;
    left: 50%;
    min-width: 800px;
    width: 80%;
    box-shadow: none;
    display: none;
    box-shadow: 1px 3px 9px -10px #b3b3b3;
}

.topnav .menu-list-row .content-active .dropdown-content {
    display: block;
}

.menu-list-row .menu__list-expand-col {
    border-left: 1px solid #e5e5e5;
    width: 165px;
}

.menu-list-row .menu__list-expand-col ul {
    display: flex;
    flex-direction: column;
}


.menu-list-row .menu__list-links-title-link>a:hover,
.menu-list-row .menu__list-links-title-link>a:active,
.menu-list-row .menu__list-links-title-link.content-active>a {
    background-color: #fff;
    /* color: #000 !important;
    font-family: gillSans, sans-serif !important; */
}

.menu-list-row .menu__list-links-title-link>a:hover,
.menu-list-row .menu__list-links-title-link>a:active,
.menu-list-row .menu__list-links-title-link.content-active>a:hover {
    background-color: #722257;
    color: #fff !important;
}


.menu-list-row .menu__list-links-title-link>a {
    padding: 17px 20px;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
}

/* .menu-list-row .menu__list-links-title-link>a::after {
    content: "";
    background: url("/assets/images/new_template/images/right.svg") no-repeat center/contain;
    width: 11px;
    height: 10px;
    padding-right: 10px;
    display: inline-block;
} */

.menu__list-expand-col .img {
    max-width: 370px;
    max-height: 190px;
    overflow: hidden;
    margin-top: 10px;
}

.menu__list-expand-col .img img {
    width: 100%;
    height: auto;
}

.menu__list-expand-col p {
    font-size: 14px;
    margin-top: 16px;
}

.menu__list-links-title a.menu__list-view-all {
    margin-top: 0;
    text-decoration: underline;
}

.menu__list-links-title a.menu__list-view-all.Earring {
    margin-top: 0;
}

.menu__list-links-title a.menu__list-view-all.Earring-last {
    margin: 0;
    padding-left: 0;
}

.menu__list-expand-col:not(:last-child),
.menu__list-expand-col ul {
    max-width: 190px;
}

.menu__list-expand-col:last-child {
    flex: 1;
    width: 100%;
    /* display: flex; */
    /* flex-direction: column; */
    /* padding-left: 26px; */
    /* justify-content: space-evenly; */
}

.menu-list-row {
    display: flex;
    position: relative;
}

.dropdown-expand.dynamic-added {
    display: none;
}

/* [prakash gurung code changed end ] */

@media screen and (max-width: 1300px) {
    .topnav {
        padding-bottom: 15px;
    }

    .topnav .dropdown-content {
        right: unset;
        top: 95%;
        left: 0;
    }

    /* [prakash gurung code changed start ] */
    .menu__list:last-child {
        padding-left: 30px;
    }

    /* [prakash gurung code changed end ] */

}

/* [prakash gurung code changed start ] */
@media screen and (max-width: 1000px) {
    .menu-list-row .menu__list-links-title-link>a {
        justify-content: flex-start;
    }

    /* .menu-list-row .menu__list-links-title-link>a::after {
        padding-left: 35px;
    } */
}

/* [prakash gurung code changed end ] */
@media screen and (max-width: 1024px) {

    .topnav .dropdown .dropbtn,
    .topnav a:not(:first-child) {
        display: none;
    }

    .topnav a.icon {
        float: right;
        display: block;
    }

    .topnav.responsive {
        position: relative;
    }

    .search-links div {
        margin-left: 15px;
    }

    .search-links a img {
        max-width: 90%;
    }

    .topnav.responsive a {
        float: none;
        display: block;
        text-align: left;
        color: #fff;
        background-color: transparent;
    }

    .topnav.responsive .dropdown {
        float: none;
    }

    .topnav.responsive .dropdown-content {
        position: relative;
        background-color: transparent;
        box-shadow: none;
    }

    .topnav.responsive .dropdown .dropbtn {
        display: block;
        width: 100%;
        text-align: left;
        color: #fff;
    }

    .topnav .dropdown>a::after {
        background: url("/assets/images/new_template/images/arrow-white.svg") no-repeat center/contain;
        margin-left: 20px;
    }

    .topnav .dropdown-content a:hover {
        background-color: transparent;
        color: #722257 !important;
    }

    .menu__list-expand-col-1 .heading {
        /* background-color: transparent; */
        color: #722257 !important;
    }

    .topnav {
        position: static;
    }

    /* RS:: Code changes start */

    /* .dropdown-expand {
        background-color: transparent;
    } */

    .dropdown-content .heading-icon,
    .menu-list-main img {
        display: none;
    }

    .dropdown-content .heading-title,
    .menu__list .expand-list li a {
        color: #fff;
    }

    .dropdown-expand .menu-list-main ul {
        position: static;
        height: unset;
        margin: 0;
        padding: 0 20px 10px;
    }

    .dropdown-expand .menu-list-main .menu__list {
        max-width: 100%;
        border: 0;
        display: block;
        padding: 10px 20px;
    }

    .menu-list-main .menu__list .col_2_content {
        max-width: 100%;
        align-items: flex-start;
    }

    .col_1_content,
    .menu-list-main {
        flex-direction: column;
    }

    .menu-list-main .col_left.menu_col {
        padding-bottom: 0;
    }

    .menu-list-main .col_right.menu_col {
        padding-top: 0;
    }

    .menu-list-main .menu__list .col_2_content {
        padding: 0 0 10px;
    }

    .heading-section .heading-title::after {
        content: '';
        background: url("/assets/images/new_template/images/right.svg") no-repeat center/contain;
        width: 11px;
        height: 10px;
        padding-right: 10px;
        display: inline-block;
        transform: rotate(90deg);
        transition: all .3s ease-in-out;
        cursor: pointer;
    }

    .menu-list-main .menu__list .col_main_content {
        display: none;
    }

    .menu-list-main .menu__list .col_main_content.active {
        display: unset;
    }

    /* [prakash gurung code changed start ] */

    .menu-list-row .menu__list-expand {
        flex-direction: column;
    }

    .menu-list-row .menu__list-expand-col ul {
        position: relative;
        padding: 0;
        height: auto;
    }

    .topnav .menu-list-row .dropdown-content {
        left: 0;
        padding-left: 30px;
    }

    .menu-list-row .menu__list-expand-col ul li a {
        padding-left: 0;
    }

    .menu-list-row .menu__list-expand-col {
        border: none;
        padding: 0;
    }

    .menu-list-row ul.menu__list-links-title {
        height: auto;
        padding: 0;
        position: relative;
    }

    .menu-list-row .menu__list-links-title-link>a {
        padding: 14px 16px;
    }

    .menu-list-row .menu__list-links-title-link>a:after {
        transform: rotate(90deg);
        margin-left: 20px;
        transition: all 400ms ease;

    }

    .menu-list-row .menu__list-links-title-link.content-active a:after {
        transform: rotate(-90deg);
    }

    .menu__list-links-title a.menu__list-view-all,
    .menu__list-links-title a.menu__list-view-all.Earring {
        margin: 0;
    }

    .menu__list-links-title a.menu__list-view-all.Earring {
        padding-left: 0;
    }

    /* [prakash gurung code changed end ] */
}

.uk-offcanvas-bar {
    background-color: #722257;
}

ul.uk-accordion-menu-bar p.none-link {
    font-size: 18px;
    color: #fff;
}

li {
    display: list-item;
    text-align: -webkit-match-parent;
}

@media screen and (min-width: 1024px) {
    .topnav ul {
        justify-content: center;
    }
}

.ribbon {
    --f: 0px;
    --r: 7px;
    --t: 4px;
    inset: var(--t) calc(-1*var(--f)) auto auto;
    background: #722257;
    height: 24px;
    width: 6.4rem;
    margin: 0 0 7px 5px;
    position: relative;
    padding: 5px 4px 0 20px;
    display: inline-block;
    vertical-align: middle;
    font-size: 11px;
    line-height: 17px;
    line-height: 15px;
    clip-path:
        polygon(0 0, 100% 0, 100% calc(100% - var(--f)),
            calc(100% - var(--f)) 100%,
            calc(100% - var(--f)) calc(100% - var(--f)),
            0 calc(100% - var(--f)),
            var(--r) calc(50% - var(--f)/2));
    animation-name: shake, shake;
    animation-duration: 1s;
    animation-delay: 2s;
    animation-iteration-count: infinite;
}

@keyframes shake {

    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

.menu__list-expand-col-0 a:hover {
    background-color: #722257 !important;
    color: #fff !important;
}

.menu__list-expand-col-1 a.heading,
.menu__list-expand-col-2 a.heading {
    color: #722257 !important;
    font-weight: 600;
    padding-top: 0;
}

.menu__list-expand-col-1 a.heading:hover,
.menu__list-expand-col-2 a.heading:hover {
    color: #722257 !important;
    background-color: #fff !important;
}

.menu__list-expand-col-1 a:hover,
.menu__list-expand-col-2 a:hover {
    background-color: #722257 !important;
    color: #fff !important;
}

 .menu__list-expand-col-1 a.quick-link,
 .menu__list-expand-col-2 a.quick-link,
 .menu__list-expand-col-3 a.quick-link {
     color: #722257 !important;
     text-decoration: underline;
}

.menu__list-expand-col-1 a.quick-link:hover,
.menu__list-expand-col-2 a.quick-link:hover,
.menu__list-expand-col-3 a.quick-link:hover{
    color: #722257 !important;
    background-color: #fff !important;
 }