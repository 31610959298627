.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: #9d9d9d;
  opacity: 0.5;
}

.placeholder.btn {
  color: transparent !important;
  cursor: wait !important;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-sm {
  min-height: .8em;
}

.placeholder-xs {
  min-height: .6em;
}

.placeholder-thumb {
  height: 100px;
  width: 100px;
}

.placeholder-image {
  height: 272px;
  width: 272px;
}

.placeholder-box {
  height: 100px;
  width: 100%;
}

.placeholder-glow .placeholder {
  -webkit-animation: placeholder-glow 2s ease-in-out infinite;
  animation: placeholder-glow 2s ease-in-out infinite;
}

.blurred-background {
  position: fixed;
  top: 0px;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999999999;
  backdrop-filter: blur(3px);
  background: #0808087d;
}

.text-sd {
  color: rgb(114, 34, 87);
}

@media screen and (max-width: 768px) {
  .smart-dialog {
    width: 100% !important;
  }
}

.smart-dialog {
  width: 50%;
}

@-webkit-keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}